/* No CSS *//*# sourceMappingURL=Terms.css.map */

.announcement-modal .modal-content {
    background-size: cover;
    background-position: 50% 50%;
}

.announcement-modal .modal-content .announcement-message {
    padding: 2rem;
    padding-right: 7rem;
    display: block;
    max-height: 50vh;
    overflow-y: auto;
}

.announcement-modal .modal-body {
    border: none !important;
}